import { createGlobalStyle } from "styled-components";
import colors from "src/assets/styles/colors.js";

const GlobalStyle = createGlobalStyle`
@font-face {
  font-family: "GT-Pressura";
  src: url('/fonts/webFonts/GT-Pressura-Mono-Regular.woff2') format('woff2'), url('webFonts/GT-Pressura-Mono-Regular.woff') format('woff');
}
@font-face {
  font-family: "PPNeueMachina";
  src: url('/fonts/webFonts/PPNeueMachina-InktrapSemibold.woff2') format('woff2'), url('webFonts/PPNeueMachina-InktrapSemibold.woff') format('woff');
}



    *{
        margin: 0;
        padding: 0;
        font-family: 'GT-Pressura', sans-serif;
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        font-weight: lighter;
        font-size: 1px;
        border: none;
        color: ${colors.white};
        text-transform: uppercase;
       
   
    }
    

  



html {width: 100%; } /* Force 'html' to be viewport width */

 body{
     overflow-x: hidden;
     width: 100vw;
     background-color: #0d0d0d;

 }
 img {
    display: none;
}

.flip {
    transform: rotate(180deg);
  }
img[src] {
   display: block;
 }

div{
  box-sizing: border-box;
}
 a, code, h1{
    font-size: 16rem;
 }
 p, span, input{
     font-size: 16rem;
 }
 input::-webkit-input-placeholder {
    font-size: 16rem;
 }
 strong {

    }

    button{
        border: none;
        margin: 0;
        padding: 0;
        width: auto;
        overflow: visible;
        background: transparent;
        color: inherit;
        font: inherit;
        line-height: normal;
        -webkit-font-smoothing: inherit;
        -moz-osx-font-smoothing: inherit;
        -webkit-appearance: none;

    
        
    }
    button:focus {
        outline: none;
        box-shadow: none;
        }
`;

export default GlobalStyle;
